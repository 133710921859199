import { CheckCircle, ExclamationTriangle } from 'bootstrap-icons-react';
import { memo, useEffect, useState } from 'react';

const StatusToast = ({ children, type = 'success', onAfterRenderResponse }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
      onAfterRenderResponse();
    }, 5000);
  }, []);

  const StatusToastClasses = () => {
    let classes = 'status-toast';
    classes += isVisible ? '' : ' hidden';
    classes +=
      type === 'success' ? ' success-border-outline' : ' error-border-outline';

    return classes;
  };

  const renderStatusToastIcon = () => {
    if (type === 'success') {
      return <CheckCircle color="#066106" />;
    } else {
      return <ExclamationTriangle color="#c6303e" />;
    }
  };

  return (
    <>
      <div className={StatusToastClasses()}>
        <span className="icon">{renderStatusToastIcon()}</span>
        <div className="body">{children}</div>
      </div>
    </>
  );
};

export default memo(StatusToast);
