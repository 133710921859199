import _ from 'lodash';

//Dealer Diagnosis API response view model interface
export const DealerDiagnosisResponse ={
    VautoDealerInfo: {
        DealerName: null,
        Address:  {
            Address1: null, 
            Address2: null, 
            Type: null, 
            City: null, 
            StateProvince: null, 
            Country: null, 
            PostalCode: null
        },
        PlatformId: null,
        SellerNumber: null
    },
    ManheimDealerInfo: {
        DealerName: null,
        Addresses: [
            {
                Address1: null, 
                Address2: null, 
                Type: null, 
                City: null, 
                StateProvince: null, 
                Country: null, 
                PostalCode: null
            }
        ],
        Representatives: [
            {
                FirstName: null, 
                MiddleName: null, 
                LastName: null, 
                Identifier: null, 
                Permissions: []
            }
        ]
    },
    HasRender: false
};

export const ErrorProperty = {
    SellerNumber: "SellerNumber",
    CVIPlatformId: "CVIPlatformId",
    Manheim: "Manheim",
    DealerInfo: "DealerInfo"
};

//Mapping Api response with view model
export const DealerDiagnosisResponseMapper = ({vauto:VautoDealerInfo, manheim:ManheimDealerInfo, errors}) => {
    const MappedData = _.cloneDeep(DealerDiagnosisResponse);

    // Set values for VautoDealerInfo
    if(VautoDealerInfo){
        MappedData.VautoDealerInfo.DealerName = VautoDealerInfo?.dealerName ?? null;
        MappedData.VautoDealerInfo.Address.Address1 = VautoDealerInfo?.address?.address1 ?? null;
        MappedData.VautoDealerInfo.Address.Address2 = VautoDealerInfo?.address?.address2 ?? null;
        MappedData.VautoDealerInfo.Address.Type = VautoDealerInfo?.address?.addressType ?? null;
        MappedData.VautoDealerInfo.Address.City = VautoDealerInfo?.address?.city ?? null;
        MappedData.VautoDealerInfo.Address.StateProvince = VautoDealerInfo?.address?.stateProvince ?? null;
        MappedData.VautoDealerInfo.Address.Country = VautoDealerInfo?.address?.country ?? null;
        MappedData.VautoDealerInfo.Address.PostalCode = VautoDealerInfo?.address?.postalCode ?? null;
        MappedData.VautoDealerInfo.PlatformId = VautoDealerInfo?.cviPlatformId ?? null;
        MappedData.VautoDealerInfo.SellerNumber = VautoDealerInfo?.sellerNumber ?? null;
    }
   
    // Set values for ManheimDealerInfo
    if(ManheimDealerInfo){
       MappedData.ManheimDealerInfo.DealerName = ManheimDealerInfo?.legalName ?? null;
       MappedData.ManheimDealerInfo.Addresses = ManheimDealerInfo.addresses.map(address => {
        return {
            Address1: address.address1 ?? null,
            Address2: address.address2 ?? null,
            Type: address.addressType ?? null,
            City: address.city ?? null,
            StateProvince: address.stateProvince ?? null,
            Country: address.country ?? null,
            PostalCode: address.postalCode ?? null
        }
       });
       MappedData.ManheimDealerInfo.Representatives = ManheimDealerInfo.representative.map(rep => {
        return {
            FirstName: rep.firstName ?? null, 
            MiddleName: rep.middleName ?? null, 
            LastName: rep.lastName ?? null, 
            Identifier: rep.oneMillion ?? null, 
            Permissions: [...rep.permissions]
        }
       });
    }
    return MappedData;
};

//Mapping Errors with corrsponding Attributes/Properties
export const ErrorMapper = ({errors:Errors}) => {
    const MappedErrors = Object.fromEntries(
        Object.keys(ErrorProperty).map(key=>[key, null])
    );

    if(Errors){
        Errors.map(error => {
            MappedErrors[error.property] = error.message;
        });
    }
    return MappedErrors;
};

