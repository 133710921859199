import { memo } from 'react';
import { ExclamationTriangleFill } from 'bootstrap-icons-react';
import { GrantExceptionModalConstants } from '../constants';

const GrantExceptionModal = ({onGrantException , onModalClose}) => {
  return (
    <div className="modal show">
      <div className="background-overlay">
        <section name="content" className="shadow-box">
          <section name="header" className="section-separator">
            <span className="header">{GrantExceptionModalConstants.HeaderText}</span>
          </section>
          <section name="body" className="section-separator">
            <div className="warning-block">
              <span className="icon">
                <ExclamationTriangleFill />
              </span>
              <p className="text">
                {GrantExceptionModalConstants.WarningText}
              </p>
            </div>
            <p className="body-text">
              {GrantExceptionModalConstants.ContentText}
            </p>
          </section>
          <section name="footer" className="buttons">
            <button
              className="cancel-btn"
              onClick={onModalClose}
            >
              Cancel
            </button>
            <button
              className="grant-exception-btn"
              onClick={onGrantException}
            >
              Grant Exception
            </button>
          </section>
        </section>
      </div>
    </div>
  );
};

export default memo(GrantExceptionModal);
