import { useEffect, useState } from 'react';
import { XCircleFill, CheckCircleFill } from 'bootstrap-icons-react'
import { DealerDiagnosisResponse, DealerDiagnosisResponseMapper, 
  ErrorMapper, ErrorProperty } from '../models/dealerDiagnosisModel';
import './index.css';

const DiagnosisDashboard = ({dealerDiagnosisResponse, dealer}) => {
  const [dealerDetails, setDealerDetails] = useState(DealerDiagnosisResponse);
  const [errors, setErrors] = useState({});

  //Static constants
  const sections = {
    DealerInfo: 'dealer-info',
    Address: 'address',
    Representatives: 'representatives',
    Errors: 'error'
  };

  const permissions = {
    Sell: 'Sell',
    Buy: 'Buy'
  }

  useEffect(() => { 
    const mappedErrors = ErrorMapper(dealerDiagnosisResponse);
    setErrors(mappedErrors);

    if(dealerDiagnosisResponse?.success){
      const result = DealerDiagnosisResponseMapper(dealerDiagnosisResponse?.data);
      setDealerDetails({...result, HasRender: true});
    } 
    
  },[dealerDiagnosisResponse]);

  const generateSection= (id, heading, body) => {
    if((errors[ErrorProperty.SellerNumber] 
      && !dealerDetails?.VautoDealerInfo?.SellerNumber) || errors[ErrorProperty.Manheim] ){

      if(id===sections.Representatives|| id===sections.Address){
        return null;
      }
    }
    
    return <>
      <section className='my-5' id={id}>
        <h5 className='ps-2'>{heading}</h5>
        <div className='shadow-box'>
          {body}
        </div>
      </section>
    </>
  };

  const RenderSection = ({id, errorProperty= null})=>{
    let heading = null;
    let body = null;
    const {PlatformId, SellerNumber, DealerName} = dealerDetails?.VautoDealerInfo;
    const {DealerName: ManheimDealerName} = dealerDetails?.ManheimDealerInfo;
   
    switch (id) {
      case sections.DealerInfo:
        heading= "Dealer Information";
        body = <>
          <div className='ps-2'><span className='fw-semibold'>MP Number: </span>{dealer}</div>
          <div className='ps-2'><span className='fw-semibold'>vAuto Dealer Name: </span>{DealerName}</div>  
          {ManheimDealerName && <div className='ps-2'><span className='fw-semibold'>Manheim Dealer Name: </span>{ManheimDealerName}</div> } 
          {!errors[ErrorProperty.CVIPlatformId] && PlatformId &&
            <div className='ps-2'>
              <span className="fw-semibold">CVI Platform Id:  </span>
              {PlatformId}
              <span className='icon'><CheckCircleFill color='green'/></span>
            </div>
          }
          {errors[ErrorProperty.CVIPlatformId] && !PlatformId && 
            <div className="ps-2">
              <span className="fw-semibold">CVI Platform Id: </span>
              {errors[ErrorProperty.CVIPlatformId]} 
              <span className='icon'><XCircleFill color='red'/></span> 
            </div>
          }
          {!errors[ErrorProperty.SellerNumber] && SellerNumber && 
            <div className='ps-2'>
              <span className='fw-semibold'>Seller Number: </span> 
              {SellerNumber} 
              <span className='icon'><CheckCircleFill color='green'/></span>
            </div>
          }
          {errors[ErrorProperty.SellerNumber] && !SellerNumber && 
            <div className='ps-2'>
              <span className='fw-semibold'>Seller Number:  </span>
              {errors[ErrorProperty.SellerNumber]}
              <span className='icon'><XCircleFill color='red'/></span> 
            </div>
          }
        </>;

        return generateSection(id, heading , body);

      case sections.Address:
        const { Address1, Type, PostalCode} = dealerDetails?.VautoDealerInfo?.Address;
        const {Addresses} = dealerDetails?.ManheimDealerInfo;
       
        heading= "Address";
        body = <>
          <div className='row ps-2'>
            <div className='col-6 ps-2' id='vauto-address-subsection'>
              <div className='fs-5 fw-bold'>Vauto</div>
              <div className='row py-2'>
                <div className='fw-medium'>
                  {`${Type} Address`}
                </div>
                <div>
                  {Address1}
                </div>
                <div>
                  {PostalCode}
                </div>
              </div>
            </div>
            <div className='col-6 ps-2' id='manheim-address-subsection'>
            <div className='fs-5 fw-bold'>Manheim</div>
              {Addresses.map((address, index) => {
                return (
                  <div className='row py-2' key={index}>
                    <div className='fw-medium'>
                      {`${address.Type} Address`}
                    </div>
                    <div>
                      <span className='d-block'>{address.Address1}</span>
                      {address.Address2 && <span className='d-block'>{address.Address2}</span>}
                      <span className='d-block'>{`${address.City}, ${address.StateProvince} ${address.PostalCode}`}</span>
                      <span className='d-block'>{address.Country}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>;
        return generateSection(id, heading , body);

      case sections.Representatives:
        heading= "Dealer Representative Details";
        body = <>
          {dealerDetails?.ManheimDealerInfo.Representatives.map((representative,index) =>
          <div className='row border-bottom border-1 ps-2 py-2' key={index}>
              <div id='representative-info-subsection'>
                <span className='me-1'>{representative.FirstName}</span> 
                {representative.MiddleName && <span className='me-1'>{representative.MiddleName}</span>}
                {representative.LastName && <span  className='me-1'>{representative.LastName}</span>}
                <span className='fw-semibold'>{`(${representative.Identifier ?? ''})`}</span>
              </div>
              <div id='permissions-subsection' className='my-2'>
                <span className='fw-semibold' >
                  Permissions: 
                </span>
                <span className='mx-3' id='sell'>
                  Sell: {representative.Permissions.includes(permissions.Sell) ? 
                  <span className='icon'><CheckCircleFill color='green' /></span > : <span className='icon'><XCircleFill color='red' /></span>}
                </span>
                <span className='mx-3' id='buy'>
                  Buy: {representative.Permissions.includes(permissions.Buy) ? 
                  <span className='icon'><CheckCircleFill color='green' /></span > : <span className='icon'><XCircleFill color='red'/></span>}
                </span>
              </div>
              <div>
                
              </div>
            </div>
          )}
        </>;
        return generateSection(id, heading , body);

      case sections.Errors:
        const errorMsg = errors[errorProperty];
        
        heading= "Error";
        body = <>
          <div className='p-2'>
           <span className='icon'><XCircleFill color='red' /></span>
           <span className='fw-semibold ms-2'>{errorMsg}</span>
          </div>  
        </>
        return generateSection(id, heading , body);

      default:
        console.error("Trying to render a section with a wrong section id!");
    }
  };

  return <>
    {dealerDetails?.HasRender && 
      <div className='Row dealer-diagnosis-dashboard'>
         <h4 className='text-center'>Dealer Diagnostic Dashboard</h4>
         {Object.values(sections).map((section) => {
           if(section != sections.Errors){
            return <RenderSection id={section} key={section}/>
           }
         })}
      </div>
    }
    {Object.keys(errors).length> 0 && errors?.DealerInfo && !dealerDetails?.HasRender && 
       <RenderSection id={sections.Errors} errorProperty={ErrorProperty.DealerInfo}/>
    }
    {Object.keys(errors).length> 0 && errors?.Manheim && dealerDetails?.HasRender && 
       <RenderSection id={sections.Errors} errorProperty={ErrorProperty.Manheim}/>
    }
  </>;
}

export default DiagnosisDashboard;
