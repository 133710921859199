import React, { useState, useCallback, useEffect } from 'react';
import { Container, Form, FormControl, Button, Stack } from 'react-bootstrap';

import {
  GrantExceptionRequest,
  GrantExceptionResponse,
  GrantExceptionResponseMapper,
} from '../models/grantExceptionModel';
import GrantExceptionModal from './grantExceptionModal';
import Loader from './loader';
import StatusToast from './statusToast';

import '../index.css';
import { StatusToastConstants } from '../constants';

const GrantExceptionPanel = ({ isViewer }) => {
  const [canOpenGrantExcetpionModal, setCanOpenGrantExceptionModal] =
    useState(false);
  const [grantExceptionResponse, setGrantExceptionResponse] = useState(
    GrantExceptionResponse
  );
  const [isLoading, setIsLoading] = useState(false);
  const [grantExceptionRequest, setGrantExceptionRequest] = useState(
    GrantExceptionRequest
  );

   // Cleanup logic using useEffect when the component unmounts
   useEffect(() => {
    return () => {
      setCanOpenGrantExceptionModal(false);
      setGrantExceptionResponse({...GrantExceptionResponse, HasRender: false});
      setIsLoading(false);
      setGrantExceptionRequest(GrantExceptionRequest);
    };
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setGrantExceptionRequest((prev) => ({
      ...prev,
      [name]: value.trim().toUpperCase(),
    }));
  };

  const handleGrantExceptionActionSubmit = (event) => {
    event.preventDefault();

    if (
      !isNullOrEmptyOrWhitespace(grantExceptionRequest.LogicalId) &&
      !isNullOrEmptyOrWhitespace(grantExceptionRequest.Vin)
    ) {
      setCanOpenGrantExceptionModal(true);
    }
  };

  const handleGrantException = useCallback(async () => {
    setIsLoading(true);
    setCanOpenGrantExceptionModal(false);

    const url = `/api/grant-exception`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(grantExceptionRequest),
    });

    await response.json().then((data) => {
      const result = GrantExceptionResponseMapper(data);
      console.log(result);
      setGrantExceptionResponse({ ...result, HasRender: true });
    });

    setIsLoading(false);
  }, [grantExceptionRequest]);

  const isNullOrEmptyOrWhitespace = (str) => !str || !str.trim().length;

  const renderResponse = useCallback(() => {
    const {Success, Message} = grantExceptionResponse;
    
    if (Success) {
      return (
        <StatusToast type="success" 
          onAfterRenderResponse = {() => setGrantExceptionResponse({...GrantExceptionResponse, HasRender: false})}>
          <h2>{StatusToastConstants.BlockedSaleToastText}</h2>
          <p>
            {StatusToastConstants.GenerateSuccessMessageWithVin(
              grantExceptionRequest.Vin
            )}
          </p>
        </StatusToast>
      );
    } else {
      return (
        <StatusToast type="error"
         onAfterRenderResponse = {() => setGrantExceptionResponse({...GrantExceptionResponse, HasRender: false})}>
          <p>{Message}</p>
        </StatusToast>
      );
    }
  },[grantExceptionResponse]);

  return (
    <>
      <Container fluid className="py-3">
        <Form
          className="d-flex"
          onSubmit={(event) => handleGrantExceptionActionSubmit(event)}
        >
          <Stack gap={3}>
            <FormControl
              type="text"
              placeholder="Dealer (Enter dealer logical id)"
              className="me-2"
              name="LogicalId"
              value={grantExceptionRequest.LogicalId}
              onChange={handleInputChange}
            />
            <FormControl
              type="text"
              placeholder="VIN (Enter vin)"
              className="me-2"
              name="Vin"
              value={grantExceptionRequest.Vin}
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              className="grant-exception-submit-btn"
              disabled={!isViewer()}
            >
              Grant Exception
            </Button>
          </Stack>
        </Form>
      </Container>
      {isLoading && !canOpenGrantExcetpionModal && <Loader />}
      {canOpenGrantExcetpionModal && (
        <GrantExceptionModal
          onGrantException={handleGrantException}
          onModalClose={() => setCanOpenGrantExceptionModal(false)}
        />
      )}
      {!isLoading &&
        !canOpenGrantExcetpionModal &&
        grantExceptionResponse.HasRender &&
        renderResponse()}
    </>
  );
};

export default GrantExceptionPanel;
