export const GrantExceptionModalConstants = {
  WarningText: `Upside Ineligible: Last sold on Upside within 90 days.`,

  ContentText: ` Granting an exception removes the 90 day Upside block immediately. 
                                                    You will need to inform the dealer that they can attempt the
                                                    Upside request again. Be sure that you have sent a SNOW ticket to
                                                    Manheim to inform them of the exception.`,

  HeaderText: `Grant Exception To Blocked Sale`,
};

export const StatusToastConstants = {
    BlockedSaleToastText: `Blocked Sale Exception Granted`,

    GenerateSuccessMessageWithVin: (vin) => `For Vin: ${vin}`
  };
  
