import React, { memo, useState } from 'react';
import {
  Container,
  Row,
  Form,
  FormControl,
  Button,
  Stack,
} from 'react-bootstrap';
import './index.css';

const DiagnosisRunActionPanel= ({ onSubmit, isViewer }) => {
  const [dealer, setDealer] = useState("");
  return (
    <>
      <Row>
        <Container fluid className="py-3">
          <Form
            className="d-flex"
            onSubmit={(event) => onSubmit(event, dealer)}
          >
            <Stack gap={3}>
              <FormControl
                id="dealer-number"
                type="text"
                placeholder="Dealer (Enter dealer number)"
                className="me-2"
                aria-label="Search Dealer"
                value={dealer}
                onChange={(e) => setDealer(e.target.value)}
              />
              <Button
                className="runDiagnosticCheck"
                type="submit"
                disabled={!isViewer()}
              >
                Run Diagnostic checks
              </Button>
            </Stack>
          </Form>
        </Container>
      </Row>
    </>
  );
}
 
export default memo(DiagnosisRunActionPanel);


