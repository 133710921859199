import _ from 'lodash';

//Grant Exception Request view model interface
export const GrantExceptionRequest = {
  Vin: '',
  LogicalId: '',
};

//Grant Exception Response view model interface
export const GrantExceptionResponse = {
  Success: false,
  StatusCode: 500,
  Message: '',
  HasRender: false
};

export const GrantExceptionResponseMapper = (data) => {
  const grantExceptionResponse = _.cloneDeep(GrantExceptionResponse);
  
  if (data) {
    grantExceptionResponse.Success = data.statusCode === 201 || data.statusCode === 200;

    if (data.statusCode) {
      grantExceptionResponse.StatusCode = data.statusCode;
    }
    if (data.message && data.message.trim().length) {
      grantExceptionResponse.Message = data.message;
    }
  }

  return grantExceptionResponse;
};
